<template>
  <div class="radio">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="radio-item"
      :class="{ active: value == item }"
      @click="itemClick(item)"
    >
      {{ index }}
    </div>
  </div>
</template>

<script>
export default {
  name: "MRadio",
  props: {
    list: {
      type: Object,
      default() {
        return [];
      },
    },
    value: {
      type: [String, Number, Boolean],
      default: "",
    },
  },
  methods: {
    itemClick(item) {
      this.active = item;
      this.$emit("update:value", item);
    },
  },
};
</script>

<style scoped lang="scss" scoped>
.radio {
  display: flex;
  gap: 10px;
  font-size: 14px;
  width: 100%;

  &-item {
    flex: 1;
    background-color: #f0f0fc;
    color: #222;
    height: 34px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    cursor: pointer;
    
    &.active {
      background-color: var(--wx-color);
      color: #fff;
    }

    .iconfont {
      font-size: 10px;
      margin-right: 3px;
      margin-left: -5px;
    }
  }
}
</style>