/**
 * 画模块化
 */

import {
    formatDate,
    timeAgo,
    dateAgo,
    random
} from "./common.js";
import {
    getRandomUser,
    getRandomPictures,
    getRandomContent
} from "./fakedata.js";

class Draw {

    constructor(options) {
        this.canvasWidth = options.canvasWidth;
        this.canvasHeight = options.canvasHeight;
        this.system = 'android';
        this.dark = false;
        this.build = 0;
        // 随机动态缓存
        this.cache = {};
        this.ctx = options.ctx
        this.dpr = options.dpr

        this.cacheImages = [];

        this.gainValue = this.canvasWidth / 1080;
        // 创建g1-g500的全局变量
        for (var i = 1; i <= 500; i++) {
            this['g' + i] = i * this.gainValue;
        }

        this.setDark(false)

        this.platform = this.platform()
    }

    platform() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        // 检测 iOS 设备
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'ios';
        }
        // 检测 Android 设备
        if (/android/i.test(userAgent)) {
            return 'android';
        }
        // // 检测 Windows 设备
        // if (/Windows/i.test(userAgent)) {
        //     return 'windows';
        // }
        // 其他设备
        return 'windows';
    }

    gain(n) {
        return n * this.gainValue;
    }

    // 设置系统
    setSystem(system) {
        this.system = system;
    }

    // 设置深夜模式
    setDark(dark) {
        this.dark = dark;
        // 基础配置
        this.fontColor = '#000';
        this.genColor = '#444';
        this.genBg = '#f7f7f7';
        if (dark) {
            this.fontColor = '#d5d5d5';
            this.genColor = '#7e7e7e';
            this.genBg = '#252525';
        }
    }

    // 设置生成方式
    setBuild(mode) {
        // 清空缓存
        // this.cache = {};
        this.build = mode;
    }

    // 获取像素数据
    getImageData(x, y, w, h) {
        x = x || 0;
        y = y || 0;
        w = w || this.canvas.width;
        h = h || this.canvas.height;
        return this.ctx.getImageData(x, y, w, h)
    }

    // 获取状态栏像素
    async getStatusData(option) {
        if (!this.cache?.status) {
            await this.drawStatusBar(option);
            this.cache.status = this.getImageData()
        }
        return this.cache.status;
    }

    // 获取动态像素
    async getDynamicData(options) {
        if (!this.cache?.dynamic) {
            await this.drawDynamic({
                ...options.param,
                ...options.baseinfo,
                comments: options.comments,
                likes: options.likes
            });
            this.cache.dynamic = this.getImageData()
        }
        return this.cache.dynamic;
    }

    // 获取点赞像素
    async getLikesData(options) {
        if (!this.cache?.likes) {
            await this.drawLikes(options);
            this.cache.likes = this.getImageData()
        }
        return this.cache.likes;
    }

    // 获取点赞和评论中间的线
    getLikesCommentLineData(options) {
        const res = this.drawLikesCommentLine(options);
        if (res) {
            return this.getImageData();
        }
        return {
            height: 0
        }
    }

    // 获取评论像素
    async getCommentData(options) {
        if (!this.cache?.comment) {
            await this.drawComment(options);
            this.cache.comment = this.getImageData()
        }
        return this.cache.comment;
    }

    // 获取底部评论框像素
    async getInputBarData() {
        if (!this.cache?.inputbar) {
            await this.drawInputBar();
            this.cache.inputbar = this.getImageData()
        }
        return this.cache.inputbar;
    }

    // 清除某个像素
    clearCache(key) {
        console.log('clear', key)
        if (!this.cache.hasOwnProperty(key)) return;
        this.cache[key] = null;
    }

    // 画
    async refresh(options) {
        console.log('refresh')

        this.setSystem(options.param.system);
        this.setDark(options.param.dark);
        this.setBuild(options.param.build);

        let datas = [];
        // 首先画状态栏
        const status = await this.getStatusData({
            ...options.param,
            ...options.baseinfo,
        })
        datas.push(status);
        // 边距
        datas.push({
            height: options.param.showcover ? this.g220 : (options.param.build == 0 ? this.g110 : 0)
        });

        // 真实动态
        let dynamics = [];
        // 画动态
        const dynamic = await this.getDynamicData(options);
        dynamics.push(dynamic);
        // 边距
        dynamics.push({
            height: this.g80
        });
        if (options.likes.length) {
            // 画点赞
            const likes = await this.getLikesData(options);
            dynamics.push(likes);
        }
        const line = this.getLikesCommentLineData(options);
        if (line.height) {
            dynamics.push(line);
        }
        if (options.comments.length) {
            // 画评论
            const comment = await this.getCommentData(options);
            dynamics.push(comment);
        }

        // 圈外
        if (this.build == 1) {
            const list = await this.drawDynamics(options);
            let head = [];
            const foot = list.slice(4);
            if (!options.param.showcover) {
                head = list.slice(0, 4);
            }
            // 在第4位插入当前的 4=top+pad+line+pad
            dynamics = [...head, ...dynamics, ...foot];
        }

        dynamics.forEach(i => {
            datas.push(i);
        })

        const height = datas.reduce((prev, cur, index, arr) => {
            return prev + cur.height;
        }, 0)

        let canvasHeight = height / this.dpr
        // 截屏幕
        if (options.param.screenshot == 0) {
            canvasHeight = this.canvasHeight;
        } else {
            canvasHeight = canvasHeight < this.canvasHeight ? this.canvasHeight : canvasHeight;
        }

        if (this.build == 0 && options.param.showcover == 0) {
            const inputBar = await this.getInputBarData();
            inputBar.top = canvasHeight * this.dpr - inputBar.height;
            datas.push(inputBar);
        }
        if (this.platform == 'ios' && canvasHeight * this.dpr > 4090) {
            canvasHeight = 4090 / this.dpr;
        }
        const ret = this.createCanvas(this.canvasWidth, canvasHeight);
        ret.ctx.fillStyle = this.dark ? "#191919" : "#fff";
        ret.ctx.fillRect(0, 0, ret.canvas.width, ret.canvas.height)
        let offset = 0;
        datas.forEach(item => {
            if (item.hasOwnProperty('data')) {
                ret.canvas.ctx.putImageData(item, 0, item?.top ? item.top : offset);
            }
            offset += item.height;
        })

        return ret.canvas.toDataURL()
    }

    // 画多条动态
    async drawDynamics(options) {
        if (this.cache?.dynamics) {
            return this.cache.dynamics;
        }

        const line = () => {
            // 边距
            datas.push({
                height: 40
            });
            // 画一条线
            datas.push(this.drawLine());
            // 边距
            datas.push({
                height: 40
            });
        }

        // 拿到当前时间
        let now = new Date(options.baseinfo.date || new Date()).getTime();

        let height = 0;
        const datas = [];
        // 画顶部动态先
        const top = await this.drawRandmod(now, true, options);
        datas.push(top);
        line();
        // 占位
        datas.push({
            height: 0
        });
        datas.forEach(i => {
            height += i.height;
        })
        // 此时的高度小于canvasheight则继续添加随机
        let i = 0;
        while ((height / this.dpr) < this.canvasHeight) {
            line();
            // 画顶部动态先
            now = now - random(600, 129600) * 1000;
            const rand = await this.drawRandmod(now, false, options);
            datas.push(rand);
            height += rand.height;
            // 防止卡死
            if (++i > 11) {
                break
            }
        }
        this.cache.dynamics = datas;
        return datas;
    }

    // 画分割线
    drawLine() {
        const {
            canvas,
            ctx
        } = this.createCanvas(this.canvasWidth, 1);
        ctx.fillStyle = this.dark ? '#212121' : "#e5e5e5";
        ctx.fillRect(0, 0, this.canvasWidth, 1)
        return this.getImageData();
    }

    /**
     * 随机画一条动态
     */
    async drawRandmod(date, istop = false, options) {
        ['user', 'pictures', 'content'].forEach(k => {
            this.cache[k] = [];
        });
        // 拿一个随机用户
        const user = await getRandomUser(this.cache.user);
        this.cache.user = [...this.cache.user, user];
        // 拿一个随机图片
        const pictures = getRandomPictures(this.cache.pictures);
        this.cache.pictures = [...this.cache.pictures, pictures];
        // 拿到一条动态内容
        const content = getRandomContent(this.cache.content);
        this.cache.content = [...this.cache.content, content];
        const baseinfo = {
            isme: false,
            date,
            content: content,
            pictures,
            attr: 0,
            picture_width: '2',
            nickname_size: options.param.nickname_size,
            text_size: options.param.text_size,
            ...user
        }
        // 画一个动态
        const dynamicCanvas = await this.drawDynamic({
            ...baseinfo
        });
        if (istop) {
            // 随机top
            const top = random(0, dynamicCanvas.height);
            return this.getImageData(0, top, null, dynamicCanvas.height - top);
        }
        return this.getImageData();
    }

    /**
     * 画status bar状态栏
     */
    async drawStatusBar(options = {}) {
        options = {
            time: '00:00',
            power: '80',
            network: '5G',
            charge: '80',
            wifi: true,
            showcover: false,
            ...options
        }
        let dark = this.dark;
        let fontColor = '#000';
        let bgColor = '#ededed';
        if (dark) {
            fontColor = '#d5d5d5';
            bgColor = '#111111';
        }
        let x = 0;
        // 44的边距必须要有
        let y = this.g44;
        // 图标高度
        const iconHeight = this.g36;
        // navbar 高度
        const navbarHeight = this.g130;
        // statusHeight
        const statusHeight = y + iconHeight - this.g10;
        const avatar_width = this.g180;
        // 封面尺寸 1080*774
        // 封面头像尺寸
        // 画布高度 + 10的下边距
        const height = options.showcover ? (this.gain(774) + avatar_width / 2.5) : (y + iconHeight + navbarHeight);
        const {
            canvas,
            ctx
        } = this.createCanvas(this.canvasWidth, height);
        // 背景
        if (options.showcover) {
            ctx.fillStyle = dark ? "#191919" : "#fff";
            ctx.fillRect(0, 0, this.canvasWidth, height);
            await this.createImage(ctx, options.cover, 0, 0, this.canvasWidth, this.gain(774));
            dark = true;
            fontColor = '#ffffff';
            // 画一个渐变黑色透明矩形
            // 设置填充样式为渐变
            ctx.fillStyle = 'rgba(0, 0, 0, .08)';
            // 绘制矩形
            ctx.fillRect(0, 0, this.canvasWidth, height - avatar_width / 2.5);

            // 画头像
            let _x = this.canvasWidth - avatar_width - this.g40;
            let _y = height - avatar_width;
            await this.createImage(ctx, options.avatar, _x, _y, avatar_width, avatar_width, this.g20);
            // 画昵称
            ctx.font = `bold ${this.g56}px Arial, sans-serif`;
            ctx.fillStyle = '#fff';
            const metrics = ctx.measureText(options.nickname);
            _x -= metrics.width + this.g25;
            _y += this.g30;
            ctx.fillText(options.nickname, _x, _y);
        } else {
            ctx.fillStyle = bgColor;
            ctx.fillRect(0, 0, this.canvasWidth, height);
        }
        const _tmpY = y;
        y = -100;
        for (let index = 0; index < 2; index++) {
            let pos = null;
            if (this.system == "android") {
                // 铃声
                pos = await this.createImagePhone(ctx, dark ? "ring_dark.png" : "ring.png", x, y, "auto",
                    iconHeight);
                x += pos.width + this.g20;
                // 蓝牙
                pos = await this.createImagePhone(ctx, dark ? "bluetooth_dark.png" : "bluetooth.png", x, y,
                    "auto", iconHeight);
                x += pos.width + this.g20;
                // 5G标识
                ctx.fillStyle = fontColor;
                ctx.font = `bold ${this.g14}px Arial, sans-serif`;
                ctx.fillText(options.network, x - this.g4, y);
            }
            // 网络
            pos = await this.createImagePhone(ctx, dark ? "network_dark.png" : "network.png", x, y, "auto",
                iconHeight);
            x += pos.width + this.g20;
            if (options.wifi) {
                // wifi
                pos = await this.createImagePhone(ctx, dark ? "wifi_dark.png" : "wifi.png", x, y, "auto",
                    iconHeight);
                x += pos.width + this.g20;
            } else if (this.system == "ios") {
                // 5G标识
                ctx.fillStyle = fontColor;
                ctx.font = `bold ${this.g36}px Arial, sans-serif`;
                const ret = this.drawText(ctx, x, y, options.network, 'auto', iconHeight, 'left', 'center');
                x += ret.width + this.g20;
            }
            // 电池
            const power_val = parseInt(options.power) > 100 ? 100 : parseInt(options.power);
            if (this.system == "android") {
                // 画电池电量文字
                ctx.fillStyle = fontColor;
                ctx.font = `${this.g32}px Arial, sans-serif`;
                const ret = this.drawText(ctx, x, y, power_val + "%", 'auto', iconHeight, 'left', 'center');
                x += ret.width + this.g4;
            }
            // 画电池图标
            pos = await this.createImagePhone(ctx, dark ? "power_dark.png" : "power.png", x, y, "auto",
                iconHeight);
            // 画电池电量
            ctx.fillStyle = power_val <= 20 ? "#e70000" : options.charge ? "#00e731" : fontColor;
            this.drawRoundedRect(ctx, x + this.g6, y + this.g6, (pos.width - (this.system == "ios" ? this.g19 : this
                .g15)) * (power_val / 100), this.g24, this.system == "ios" ? this.g6 : this.g2);
            if (options.charge) {
                if (this.system == "android") {
                    x += pos.width;
                    // 画充电状态
                    pos = await this.createImagePhone(ctx, dark ? "charge_dark.png" : "charge.png", x + this
                        .g10, y, "auto", iconHeight);
                } else {
                    // 画充电状态
                    await this.createImagePhone(ctx, dark ? "charge_dark.png" : "charge.png", x + this.g15, y,
                        "auto", iconHeight);
                }
            }
            x += pos.width + this.g60;
            if (index == 0) {
                x = this.canvasWidth - x;
                y = _tmpY;
            }
        }
        // 时间
        ctx.fillStyle = fontColor;
        ctx.font = `bold ${this.g36}px Arial, sans-serif`;
        this.drawText(ctx, this.g60, y, formatDate(options.time, "HH:mm"), 'auto', iconHeight, 'left', 'center');
        y += this.g46;
        // ****** 画navbar ******
        if (!options.showcover) {
            // 写文字
            ctx.font = "bold " + this.g46 + "px Arial, sans-serif";
            this.drawText(ctx, 0, y, this.build == 0 ? '详情' : '朋友圈', this.canvasWidth, navbarHeight, 'center',
                'center');

            // 更多
            await this.createImageIcon(ctx, dark ? "more_dark.png" : "more.png", this.canvasWidth - this.g42 - this
                .g50,
                y + (navbarHeight - this.g48) / 2, this.g48, this.g48);

        } else {
            // 相机
            await this.createImageIcon(
                ctx,
                "photo.png",
                this.canvasWidth - this.g105,
                y + (navbarHeight - this.g42) / 2 - this.g4,
                this.g54,
                this.g56
            );
        }

        // 返回
        await this.createImageIcon(ctx, dark ? "back_dark.png" : "back.png", this.g40, y + (navbarHeight -
            this.g46) / 2, this.g46, this.g46);

        // 画灵动岛
        // ctx.fillStyle = '#000000';
        // await this.drawRoundedRect(ctx, (this.canvasWidth - this.g300) / 2, this.g26, this.g300, statusHeight, statusHeight / 1.7); 
        return canvas;
    }

    /**
     * 画底部评论框
     */
    async drawInputBar() {
        let bgColor = '#f8f8f8';
        if (this.dark) {
            bgColor = '#2c2c2c';
        }
        const height = this.g135;
        let x = 0,
            y = 0;
        const {
            canvas,
            ctx
        } = this.createCanvas(this.canvasWidth, height);
        // 画背景
        ctx.fillStyle = bgColor;
        ctx.fillRect(x, y, this.canvasWidth, height);
        x = this.g30;
        y = this.g20;
        // 画一个圆角矩形
        const rectWidth = this.canvasWidth - x - this.g300;
        const rectHeight = height - this.g20 * 2;
        ctx.fillStyle = this.dark ? '#191919' : '#ffffff';
        this.drawRoundedRect(ctx, x, y, rectWidth, rectHeight, this.g16);
        // 写文字
        ctx.fillStyle = '#999';
        ctx.font = `${this.g40}px Arial, sans-serif`;
        this.drawText(ctx, x + this.g20, y, '评论', 'auto', rectHeight, 'left', 'center');
        x += rectWidth;
        x += this.g30;
        // 画表情符号
        await this.createImageIcon(
            ctx,
            this.dark ? "face_dark.png" : "face.png",
            x,
            (height - this.g70) / 2,
            this.g70,
            this.g70
        );
        x += this.g30 + this.g70;
        // 画发送按钮
        ctx.fillStyle = '#999';
        y += this.g10;
        const btnWidth = this.g140;
        const btnHeight = height - y * 2;
        this.drawRoundedRect(ctx, x, y, btnWidth, btnHeight, this.g5);
        ctx.fillStyle = bgColor;
        this.drawRoundedRect(ctx, x + this.g1, y + this.g1, btnWidth - this.g2, btnHeight - this.g2, this.g5);
        // 写文字
        ctx.fillStyle = '#999';
        ctx.font = `${this.g36}px Arial, sans-serif`;
        this.drawText(ctx, x, y, '发送', btnWidth, btnHeight, 'center', 'center');
        return canvas;
    }

    /**
     * 画一条动态
     */
    async drawDynamic(options = {}, canvas_height = 0) {
        options = {
            avatar: '/static/pyq/icons/default-avatar.jpg',
            nickname: '没有昵称',
            content: {
                ops: [],
                html: ''
            },
            pictures: [],
            date: '',
            isme: true,
            likes: [],
            comments: [],
            attr: 0,
            location: '',
            ...options,
        }
        const parsedDate = new Date(options.date);
        options.date = parsedDate instanceof Date && !isNaN(parsedDate) ? parsedDate : new Date();
        // 坐标
        let x = 0;
        let y = 0;
        // 边距
        const padding = this.g40;
        // 真实的绘画宽度
        const width = this.canvasWidth - padding * this.g2;
        // 预设画布高度为2个屏幕高度，因为我也不知道用户会画多少东西
        let height = canvas_height;
        // 从边距开始画
        x += padding;
        // 创建一个画布
        const {
            canvas,
            ctx
        } = this.createCanvas(this.canvasWidth, height);
        // 背景
        ctx.fillStyle = this.dark ? "#191919" : "#fff";
        ctx.fillRect(0, 0, this.canvasWidth, height)
        // 画头像
        await this.createImage(ctx, options.avatar, x, y, this.g120, this.g120, this.g14);
        // 画昵称
        // 此处更新坐标，昵称内容图片坐标起点
        x += this.g145;
        // 加点边距
        y += this.g10;
        ctx.fillStyle = "#5a6d97";
        const nicknameFontSize = parseInt(this.g44 * options.nickname_size);
        ctx.font = `bold ${nicknameFontSize}px Arial, sans-serif`;
        ctx.fillText(options.nickname, x, y);
        // 坐标加文字高度
        y += this.g40;
        // 内容和图片的盒子宽度 总宽度减去当前的x再减一个边距
        const contentWidth = this.canvasWidth - x - padding;
        // 画内容
        if (options.content.length) {
            ctx.fillStyle = this.fontColor;
            const contentFontSize = parseInt(this.g44 * options.text_size);
            ctx.font = `${contentFontSize}px Arial, sans-serif`;
            // 更新坐标加边距
            y += this.g34;
            // 有内容的时候画出来
            const lineHeight = await this.drawWrappedText(
                ctx,
                options.content,
                x,
                y,
                contentWidth,
                this.g60
            );
            // 更新y的坐标
            y += lineHeight - this.g10;
        }
        // 画链接
        if (options.attr == 1) {
            // 更新坐标加边距
            y += this.g30;
            ctx.fillStyle = "#00000000";
            ctx.font = `${this.g37}px Arial, sans-serif`;
            let font = '',
                fontw = 0,
                maxw = (contentWidth - this.g180) * 2;
            for (var i = 0; i < options.link.title.length; i++) {
                fontw += ctx.measureText(options.link.title[i]).width;
                if (fontw >= maxw) {
                    // 删除最后一个字符
                    font = font.slice(0, -1);
                    font += '...';
                    break;
                }
                font += options.link.title[i];
            }
            const fontHeight = await this.drawWrappedText(ctx, font, x + this.g160, y, contentWidth -
                this.g180,
                this.g50)
            const fontTop = y + ((this.g140 - fontHeight + this.g14) / 2)
            // 画背景矩形
            ctx.fillStyle = this.genBg;
            this.drawRoundedRect(
                ctx,
                x,
                y,
                contentWidth,
                this.g140
            );
            // 画icon
            await this.createImage(ctx, options.link.cover, x + this.g10, y + this.g10, this.g120, this.g120);
            ctx.fillStyle = "#333";
            await this.drawWrappedText(ctx, font, x + this.g160, fontTop, contentWidth - this.g180,
                this.g50)
            // 更新y的坐标
            y += this.g140;
        }
        // 画图片
        if (options.pictures.length && options.attr == 0) {
            if (options.pictures.length == 1) {
                // 更新坐标加边距
                y += this.g34;
                const diff = (contentWidth / options.picture_width);
                const pic_width = contentWidth - (diff === Infinity ? this.g30 : diff);
                const res = await this.createImage(
                    ctx,
                    options.pictures[0],
                    x,
                    y,
                    pic_width,
                    "auto"
                );
                // 更新坐标加图片高度
                y += res.height;
            } else {
                // 更新坐标加边距
                y += this.g34;
                // 一张以上图片的宽度则为容器宽度的1/3 + 边距
                let pictureWidth = contentWidth / 3 - this.g15;
                // 4张图片
                let cutup = 3;
                if (options.pictures.length == 4) {
                    cutup = 2;
                }
                let _x = x;
                let _y = y;
                for (let i = 0; i < options.pictures.length; i++) {
                    await this.drawImageCover(ctx, options.pictures[i], _x, _y, pictureWidth);
                    _x += pictureWidth + this.g5;
                    if ((i + 1) % cutup == 0) {
                        _y += pictureWidth + this.g5;
                        _x = x;
                    }
                }
                // 更新y的坐标 图片宽度乘以行数
                y += (pictureWidth + this.g5) * Math.ceil(options.pictures.length / cutup);
            }
        }
        // 更新坐标加边距
        y += this.g20;
        // 画位置
        if (options.location.length) {
            y += this.g20;
            ctx.fillStyle = '#5a6d97';
            ctx.font = `${this.g34}px Arial, sans-serif`;
            let font = '',
                fontw = 0;
            for (var i = 0; i < options.location.length; i++) {
                fontw += ctx.measureText(options.location[i]).width;
                if (fontw > contentWidth) {
                    font += '...';
                    break;
                }
                font += options.location[i];
            }

            // 宽度大于contentWidth宽度删除文字用三个点代替
            ctx.fillText(font, x, y);
            // 更新坐标加边距
            y += this.g50;
        }
        y += this.g20;
        // 记录此时的x,y  TODO 没得办法了，只能通过这种方法做到局部更新了
        canvas.date = {
            x,
            y,
            w: 0
        };
        canvas.drawDate = async (date) => {
            // 画时间
            ctx.fillStyle = this.dark ? this.genColor : '#888888';
            ctx.font = `${this.g36}px Arial, sans-serif`;
            if (this.build == 1) {
                // 如果是圈内的话，时间要个性显示
                date = timeAgo(date);
            } else {
                date = dateAgo(date);
            }
            // date width
            const metrics = ctx.measureText(date);
            const width = metrics.width + this.g58;
            // 清除区域
            ctx.clearRect(canvas.date.x, canvas.date.y - this.g10, canvas.date.w, this.g38 + this.g10);
            ctx.textBaseline = "top";
            ctx.fillText(date, canvas.date.x, canvas.date.y);
            let _x = canvas.date.x + metrics.width + this.g30,
                _y = canvas.date.y - this.g3
            if (options.top) {
                // 画置顶
                await this.createImageIcon(
                    ctx,
                    "top.png",
                    _x,
                    _y,
                    this.g32,
                    this.g38
                );
                _x += this.g32 + this.g36;
            }
            if (options.limit) {
                // 画部分好友可见
                await this.createImageIcon(
                    ctx,
                    "limit.png",
                    _x,
                    _y,
                    this.g38,
                    this.g39
                );
                _x += this.g38 + this.g36;
            }
            if (options.isme) {
                // 画删除按钮
                await this.createImageIcon(
                    ctx,
                    "delete.png",
                    _x,
                    _y,
                    this.g34,
                    this.g38
                );
            }
            // 记录宽度
            canvas.date.w = width;
        }
        await canvas.drawDate(options.date);

        // 画操作按钮
        y -= this.g6;
        // 移动x到操作按钮位置，使用画布宽度减去边距减去操作按钮宽度
        x = this.canvasWidth - padding - this.g88;
        ctx.fillStyle = this.dark ? "#2c2c2c" : "#f8f8f8";
        this.drawRoundedRect(ctx, x, y - this.g4, this.g94, this.g55, this.g8);
        // 画按钮中的圆点
        for (let i = 0; i < 2; i++) {
            ctx.fillStyle = "#5a6d97";
            ctx.beginPath();
            ctx.arc(x + this.g34 + i * this.g24, y + this.g24, this.g5, 0, Math.PI * 2);
            ctx.closePath();
            ctx.fill();
            // 设置描边颜色和宽度
            ctx.strokeStyle = "#5a6d97";
            ctx.lineWidth = 1;
            // 描边圆
            ctx.stroke();
        }
        // y坐标加上按钮高度
        y += this.g55;
        // 更新画布高度
        height = y;
        if (canvas_height == 0) {
            return await this.drawDynamic(options, height);
        }
        return canvas;
    }

    /**
     * 画点赞
     */
    async drawLikes(options) {
        const list = options.likes
        const likenum = options.param.likenum;
        // ******* 画点赞 ********
        if (!list.length) {
            return {
                height: 0
            }
        }
        let height = 0;

        // 真实的绘画宽度
        const width = this.canvasWidth - this.g40 * 2;
        let x = this.g40;
        // 更新y坐标加边距
        let y = 0;
        height += y;
        // icon 宽度18 + 左右边距10
        const icon_width = this.g50 + this.g34;
        // 真实的点赞头像容器宽度 画布宽度减去两个边距
        let avatar_warp_width = width - icon_width;

        const createCanvas = (height) => {
            // 创建一个画布
            const {
                canvas,
                ctx
            } = this.createCanvas(this.canvasWidth, height);
            // 背景
            ctx.fillStyle = this.dark ? "#191919" : "#fff";
            ctx.fillRect(0, 0, this.canvasWidth, height)

            let bgwidth = width,
                bgleft = x,
                radius = this.g10;
            if (this.build == 1) {
                bgwidth -= this.g145;
                bgleft = this.g145 + this.g40;
                if (options?.comments && options?.comments.length) {
                    radius = {
                        tl: this.g12,
                        tr: this.g12,
                        br: 0,
                        bl: 0
                    }
                }
            }

            // 画圆角背景矩形
            ctx.fillStyle = this.genBg;
            this.drawRoundedRect(
                ctx,
                bgleft,
                0,
                bgwidth,
                height,
                radius
            );
            return {
                canvas,
                ctx
            }
        }

        let canvas = null;

        // 先取到画布高度
        if (this.build == 0) {
            // 头像宽度
            const avatar_width = this['g' + [100, 94, 83, 74][likenum]];
            // const avatar_width = this.g74;
            // 一个头像元素宽度
            const item_width = avatar_width + this.g17;
            // 一行显示多少个头像
            const avatar_count = Math.floor(avatar_warp_width / item_width);
            // 头像行
            const avatar_line = Math.ceil(list.length / avatar_count);
            // 评论行数 * 评论头像高度 + 上下边距 - 头像下边距
            height += (item_width * avatar_line) + this.g60 - this.g17

            const cav = createCanvas(height);
            canvas = cav.canvas;
            const ctx = cav.ctx;

            // 更新y边距
            y += this.g30;
            // 画icon
            await this.createImageIcon(ctx, "like.png", x + this.g14, y + this.g10, this.g55, this.g55);
            // 更新此时的x
            x += icon_width;
            // 画头像
            let _x = x;
            for (let index = 0; index < list.length; index++) {
                const item = list[index];
                _x = _x + item_width;
                if (index % avatar_count == 0) {
                    _x = x;
                    y += item_width;
                }
                await this.createImage(
                    ctx,
                    item.avatar,
                    _x,
                    y - item_width,
                    avatar_width,
                    avatar_width,
                    this.g7
                );
            }
        } else {

            avatar_warp_width -= this.g145;
            x += this.g145;

            const tmp = this.createCanvas(this.canvasWidth, height);
            const contentFontSize = parseInt(this.g36 * options.param.text_size);
            const font = `${contentFontSize}px Arial, sans-serif`;
            const nicknames = list.map(i => i.nickname).join(', ');
            tmp.ctx.font = font;
            // 只为了取到文字高度
            const lineHeight = await this.drawWrappedText(tmp.ctx, nicknames, x, y, avatar_warp_width, this.g50)
            // 上下15的边距 -5是文字行高
            height += lineHeight + this.g15 * 2 - this.g5;

            const cav = createCanvas(height);
            canvas = cav.canvas;
            const ctx = cav.ctx;
            ctx.font = font;

            // 画icon
            await this.createImageIcon(ctx, "like.png", x + this.g16, y + this.g16, this.g50, this.g50);
            ctx.fillStyle = "#5a6d97";
            // 圈内只显示昵称 为什么不是y + 20而是y + 25呢，我发现文字会自己冒出一点头来，不知道为什么...
            this.drawWrappedText(ctx, nicknames, x + icon_width, y + this.g25, avatar_warp_width - this.g20, this
                .g50)
            y += lineHeight + this.g18;
        }
        // 更新画布高度
        return canvas;
    }

    /**
     * 画点赞和评论中间的间距
     */
    drawLikesCommentLine(options) {
        if (!options?.likes || !options.likes.length || !options?.comments || !options.comments.length) {
            return null;
        }
        let height = 1;
        if (this.build == 0) {
            height = this.g2 < 1 ? 1 : this.g2;
        }
        const {
            canvas,
            ctx
        } = this.createCanvas(this.canvasWidth, height);
        // 背景
        ctx.fillStyle = this.dark ? "#191919" : "#fff";
        ctx.fillRect(0, 0, this.canvasWidth, height)
        if (this.build == 1) {
            ctx.fillStyle = this.dark ? "#212121" : "#e5e5e5";
            ctx.fillRect(this.g185, 0, this.canvasWidth - this.g185 - this.g40, height)
        }
        return canvas;
    }

    /**
     * 画评论
     */
    async drawComment(options) {
        const list = options.comments
        const likenum = options.param.likenum;
        // ******* 画评论 *******
        if (!list.length) {
            return {
                height: 0
            }
        }
        // 真实的绘画宽度
        const width = this.canvasWidth - this.g40 * 2;
        // x归位
        let x = this.g40;
        let y = 0;
        // icon 宽度45 + 左右边距17
        const icon_width = this.g45 + this.g34;


        // 创建一个画布 此画布为最高的可用画布高度 那么就是4090
        let {
            canvas,
            ctx
        } = this.createCanvas(this.canvasWidth, 0);

        let _tmpX = x;
        let _tmpY = y + this.g26;
        _tmpX += icon_width;

        const contentFontSize = parseInt(40 * options.param.text_size);
        const fontSize = this['g' + (contentFontSize - likenum)];

        let _x = _tmpX;
        let _y = _tmpY;
        // 画两遍，第一遍为了知道高度
        for (let index = 0; index < 2; index++) {
            _y = _tmpY;
            _x = _tmpX;
            for (let j = 0; j < list.length; j++) {
                const item = list[j];
                if (this.build == 0) {
                    // 评论的容器宽度
                    const comment_warp_width = width - icon_width;
                    _x = _tmpX;
                    // 画头像
                    // const avatar_width = this.g74;
                    const avatar_width = this['g' + [100, 94, 83, 74][likenum]];
                    await this.createImage(ctx, item.avatar, _x, _y, avatar_width, avatar_width, this.g7);
                    _x += avatar_width + this.g20;
                    // 真实的内容宽度
                    const content_width = comment_warp_width - avatar_width - this.g30;
                    // 画昵称
                    ctx.fillStyle = "#5a6d97";
                    const nicknameFontSize = parseInt((fontSize - this.g2) * options.param.nickname_size);
                    ctx.font = `500 ${nicknameFontSize}px Arial, sans-serif`;
                    ctx.fillText(item.nickname, _x, _y);
                    // 画日期
                    ctx.fillStyle = "#777";
                    ctx.font = `${this['g' + [32, 31, 30, 28][likenum]]}px Arial, sans-serif`;
                    const date = dateAgo(new Date(item.date), 'yyyy年MM月dd日 HH:mm');
                    const metrics = ctx.measureText(date);
                    ctx.fillText(date, width - metrics.width + this.g10, _y);
                    // 字体高度
                    _y += fontSize - this.g2;//this.g34;
                    // 上边距
                    // _y += this.g20;
                    _y += this['g' + [30, 28, 24, 20][likenum]];
                    // 如果是回复
                    let offset = 0;
                    if (item?.quote) {
                        ctx.fillStyle = this.fontColor;
                        ctx.font = `${fontSize}px Arial, sans-serif`;
                        const metrics = ctx.measureText("回复");
                        let _qx = _x;
                        ctx.fillText("回复", _qx, _y);
                        ctx.fillStyle = "#5a6d97";
                        ctx.font = `${fontSize + this.g2}px Arial, sans-serif bold`;
                        _qx += metrics.width + this.g4;
                        ctx.fillText(item.quote.nickname, _qx, _y);
                        const metrics2 = ctx.measureText(item.quote.nickname);
                        offset = metrics2.width + this.g4 + metrics.width + this.g4;
                    }
                    // 画评论内容
                    ctx.fillStyle = this.fontColor;
                    ctx.font = `${fontSize + this.g3}px Arial, sans-serif`;
                    const lineHeight = await this.drawWrappedText(
                        ctx,
                        item.content,
                        _x,
                        _y,
                        content_width,
                        this.g54,
                        offset
                    );
                    _y += lineHeight + this.g30;
                } else {
                    // 评论的容器宽度-头像加边距宽度...
                    // this.g145取自drawDynamic
                    const comment_warp_width = width - this.g145 - this.g10;
                    _x = x;
                    _x += this.g145 + this.g26;
                    // 来吧，这代码我也没法写了，感觉已经屎山了
                    // 如果是圈内的话，它不显示头像和日期...
                    // 画昵称
                    ctx.fillStyle = "#5a6d97";
                    ctx.font = `500 ${fontSize - this.g2}px Arial, sans-serif`;
                    ctx.fillText(item.nickname, _x, _y);
                    // 取到昵称宽度
                    const metrics = ctx.measureText(item.nickname);
                    // 如果是回复
                    let offset = metrics.width + this.g2;
                    if (item?.quote) {
                        ctx.fillStyle = this.fontColor;
                        const metrics = ctx.measureText("回复");
                        let _qx = _x;
                        ctx.fillText("回复", _qx, _y);
                        ctx.fillStyle = "#5a6d97";
                        ctx.font = `${fontSize + this.g2}px Arial, sans-serif bold`;
                        _qx += metrics.width + this.g2;
                        ctx.fillText(item.quote.nickname, _qx, _y);
                        const metrics2 = ctx.measureText(item.quote.nickname);
                        offset += metrics2.width + this.g2 + metrics.width + this.g2;
                    }
                    // 画内容
                    ctx.fillStyle = this.fontColor;
                    ctx.font = `${fontSize}px Arial, sans-serif`;
                    const lineHeight = await this.drawWrappedText(ctx, '：' + item.content,
                        _x, _y, comment_warp_width - this.g20, this.g54, offset);
                    _y += lineHeight + this.g14 * options.param.text_size;
                }
            }
            // 去掉最后一个边距，此时的_y则是整个动态的高度
            _y -= this.g30;
            // 加下边距
            _y += this.g20;
            if (index == 0) {
                if (this.platform == 'ios' && _y * this.dpr > 4090) {
                    _y = 4090 / this.dpr;
                }
                const tmp_canvas = this.createCanvas(this.canvasWidth, _y);
                ctx = tmp_canvas.ctx;
                canvas = tmp_canvas.canvas;
                let bgwidth = width,
                    bgleft = x,
                    radius = 0;
                if (this.build == 1) {
                    bgwidth -= this.g145;
                    bgleft = this.g145 + this.g40;
                    if (options?.likes && options?.likes.length) {
                        radius = {
                            tl: 0,
                            tr: 0,
                            br: this.g12,
                            bl: this.g12
                        }
                    }
                }
                // 背景
                ctx.fillStyle = this.dark ? "#191919" : "#fff";
                ctx.fillRect(0, 0, this.canvasWidth, _y)
                // 画圆角背景矩形
                ctx.fillStyle = this.genBg;
                // 减去y（y所有上面的元素的高度），是评论区内容的高度（不包含上下边距）
                this.drawRoundedRect(ctx, bgleft, y, bgwidth, _y, radius);
                if (this.build == 0) {
                    // 画icon
                    await this.createImageIcon(ctx, "comment.png", x + this.g14, y + (this.build == 0 ? this.g38 :
                        this.g10), this.g50, this.g50);
                }
                // 更新y
                y += _y;
            }
        }
        return canvas;
    }

    // 创建一个离屏渲染画布
    createCanvas(width, height) {
        if (!this.canvas || !this.ctx) {
            this.canvas = document.createElement("canvas");
            this.ctx = this.canvas.getContext("2d");
            this.ctx.getCanvas = this.canvas;
            this.canvas.ctx = this.ctx;
        }

        this.canvas.width = width * this.dpr;
        this.canvas.height = height * this.dpr;
        this.ctx.scale(this.dpr, this.dpr)
        this.ctx.textBaseline = "top";
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

        return {
            canvas: this.canvas,
            ctx: this.ctx
        };
    }

    // 创建一个图片
    createImage(ctx, src, x, y, width, height, radius = null) {
        return new Promise(async (resolve, reject) => {
            // 创建一个图片
            let img = null;
            const load = () => {
                if (radius) {
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x + radius, y);
                    ctx.lineTo(x + width - radius, y);
                    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
                    ctx.lineTo(x + width, y + height - radius);
                    ctx.quadraticCurveTo(
                        x + width,
                        y + height,
                        x + width - radius,
                        y + height
                    );
                    ctx.lineTo(x + radius, y + height);
                    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
                    ctx.lineTo(x, y + radius);
                    ctx.quadraticCurveTo(x, y, x + radius, y);
                    ctx.closePath();
                    ctx.clip();
                }
                // Draw the image
                if (width == "auto") {
                    width = img.width * (height / img.height);
                }
                if (height == "auto") {
                    height = img.height * (width / img.width);
                }
                ctx.drawImage(img, x, y, width, height);
                if (radius) {
                    ctx.restore();
                }
                resolve({
                    width,
                    height,
                });
            };
            // 检查src是否存在
            if (!this.cacheImages.some(i => i.src == src && (img = i.img))) {
                img = document.createElement("img");
                img.crossOrigin = 'anonymous';
                img.src = src;
                // 不在的话就加载，并且push缓存中
                img.onload = () => {
                    this.cacheImages.push({
                        src,
                        img
                    });
                    load();
                }
            } else {
                // 存在则直接load
                load();
            }
            img.onerror = () => {
                resolve({
                    width: 0,
                    height: 0,
                });
            }
        });
    }
    // 创建一个图片指向Icon目录
    createImageIcon(ctx, src, x, y, width, height, radius) {
        src = '/icons/' + src;
        return this.createImage(ctx, src, x, y, width, height, radius);
    }
    // 创建一个图片指向手机型号
    createImagePhone(ctx, src, x, y, width, height, radius) {
        src = this.system + "/" + src;
        return this.createImage(ctx, src, x, y, width, height, radius);
    }

    findLinks(text) {
        // 正则表达式确保域名部分只包含英文字符、数字、连字符和点，并支持 URL 中的常见特殊字符
        const linkPattern = /https?:\/\/[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[^\s\u4e00-\u9fa5]*)?/g;
        let match;
        let positions = [];

        while ((match = linkPattern.exec(text)) !== null) {
            positions.push({
                url: match[0], // 匹配到的链接
                start: match.index, // 链接起始位置
                end: match.index + match[0].length // 链接结束位置
            });
        }

        return positions;
    }

    // 解析文本
    parseHtml(html) {
        const result = [];

        // 创建一个临时的div容器来解析HTML
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // 遍历子元素
        tempDiv.childNodes.forEach(node => {
            if (node.nodeName === 'IMG') {
                // 如果是图片，提取src
                result.push({ image: node.getAttribute('src') });
            } else if (node.nodeName === 'BR') {
                // 如果是换行符，推入 '\n'
                result.push('\n');
            } else if (node.nodeType === Node.TEXT_NODE) {
                // 如果是文本节点，去除空白字符并推入文本
                const textContent = node.textContent.trim();
                if (textContent) result.push(textContent);
            }
        });

        return result;
    }
    // 换行文本
    async drawWrappedText(ctx, text, x, y, maxWidth, lineHeight, offset = 0) {
        text = this.parseHtml(text);
        // 保存当前文字颜色
        const tmpColor = ctx.fillStyle;
        let linenum = 0;
        const left = x;
        let _x = x + offset;
        for (let ti = 0; ti < text.length; ti++) {
            const item = text[ti];
            if (typeof item === 'string') {
                // 找到链接位置
                const links = this.findLinks(item);
                const words = Array.from(item);
                for (let i = 0; i < words.length; i++) {
                    const testLine = words[i];
                    if (testLine == "\n") {
                        y += lineHeight;
                        linenum++;
                        _x = left;
                        continue;
                    }

                    // 是否是链接
                    const isLink = links.some(link => link.start <= i && i < link.end);

                    if (isLink) {
                        // 链接颜色
                        ctx.fillStyle = "#5a6d97";
                    } else {
                        // 文字颜色
                        ctx.fillStyle = tmpColor;
                    }

                    const metrics = ctx.measureText(testLine);
                    const testWidth = metrics.width;
                    // 剩余空间
                    const remainingWidth = maxWidth - (_x - left);
                    if (remainingWidth < testWidth) {
                        y += lineHeight;
                        linenum++;
                        _x = left;
                    }
                    ctx.fillText(testLine, _x, y);
                    _x += testWidth;
                }
            } else {
                if (!item?.image) {
                    continue;
                }
                // 表情
                const src = item.image;
                const width = this.g54;
                // 剩余空间
                const remainingWidth = maxWidth - (_x - left);
                if (remainingWidth < width) {
                    y += lineHeight;
                    linenum++;
                    _x = left;
                }
                await this.createImage(ctx, src,
                    _x,
                    y - this.g10, width, width);
                _x += width;
            }
        }
        linenum++;
        return linenum * lineHeight;
    }

    // 以cover模式绘制正方形容器图片
    drawImageCover(ctx, src, squareX, squareY, squareSize) {
        return new Promise((resolve, reject) => {
            // 创建一个图片
            let img = null;
            const load = () => {
                const imageWidth = img.width;
                const imageHeight = img.height;
                const imageAspectRatio = imageWidth / imageHeight;
                const squareAspectRatio = 1; // 正方形的宽高比为 1

                let renderWidth, renderHeight, offsetX, offsetY;

                // 根据宽高比调整渲染大小
                if (imageAspectRatio > squareAspectRatio) {
                    // 图片更宽，缩放高度填充正方形，裁剪宽度
                    renderHeight = squareSize;
                    renderWidth = renderHeight * imageAspectRatio;
                    offsetX = (squareSize - renderWidth) / 2;
                    offsetY = 0;
                } else {
                    // 图片更高，缩放宽度填充正方形，裁剪高度
                    renderWidth = squareSize;
                    renderHeight = renderWidth / imageAspectRatio;
                    offsetX = 0;
                    offsetY = (squareSize - renderHeight) / 2;
                }

                // 保存上下文并设置裁剪区域
                ctx.save();
                ctx.beginPath();
                ctx.rect(squareX, squareY, squareSize, squareSize);
                ctx.clip();

                // 将图片绘制到正方形区域内
                ctx.drawImage(
                    img,
                    squareX + offsetX,
                    squareY + offsetY,
                    renderWidth,
                    renderHeight
                );

                // 恢复上下文到未裁剪状态
                ctx.restore();

                resolve({
                    width: imageWidth,
                    height: imageHeight,
                });
            }
            // 检查src是否存在
            if (!this.cacheImages.some(i => i.src == src && (img = i.img))) {
                img = document.createElement('img');
                img.crossOrigin = 'anonymous';
                img.src = src;
                // 不在的话就加载，并且push缓存中
                img.onload = () => {
                    this.cacheImages.push({
                        src,
                        img
                    });
                    load();
                }
            } else {
                // 存在则直接load
                load();
            }
            img.onerror = () => {
                msg('网络错误');
                resolve({
                    width: 0,
                    height: 0,
                });
            }
        });
    }
    // 画一个圆角矩形
    drawRoundedRect(ctx, x, y, width, height, radius) {
        if (typeof radius === "number") {
            radius = {
                tl: radius,
                tr: radius,
                br: radius,
                bl: radius
            };
        } else {
            radius = Object.assign({
                tl: 0,
                tr: 0,
                br: 0,
                bl: 0
            }, radius);
        }

        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(
            x + width,
            y + height,
            x + width - radius.br,
            y + height
        );
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        ctx.fill();
    }
    /**
     * 画一个文字可设置对齐
     * @param {*} ctx 
     * @param {*} text 文本
     * @param {*} warpWidth 容器宽度
     * @param {*} warpHeigh 容器高度
     * @param {*} horizontal center left right 水平
     * @param {*} vertical center top bottom 垂直
     */
    drawText(ctx, x, y, text, warpWidth, warpHeigh, horizontal = 'left', vertical = 'top') {
        const metrics = ctx.measureText(text);
        // 拿到文字宽度
        const textWidth = metrics.width;
        // 拿到文字高度
        const match = ctx.font.match(/\d+(\.\d+)?/); // \d+ 匹配整数部分，(\.\d+)? 匹配小数部分
        let fontSize = 0;
        if (match) {
            fontSize = match[0];
        }
        const textHeight = fontSize; //metrics.fontBoundingBoxAscent // + metrics.actualBoundingBoxDescent;
        if (warpWidth === 'auto') {
            warpWidth = textWidth;
        }

        if (horizontal == 'center') {
            x += warpWidth / 2 - textWidth / 2;
        } else if (horizontal == 'right') {
            x += warpWidth - textWidth;
        }

        if (vertical == 'center') {
            y += (warpHeigh - textHeight) / 2;
        } else if (vertical == 'bottom') {
            y += warpHeigh - textHeight;
        }
        ctx.fillText(text, x, y);

        return {
            width: warpWidth,
            height: textHeight
        };
    }
}


export default Draw;