<template>
  <div class="editor">
    <!-- 编辑器 -->
    <div
      ref="editorContent"
      class="editor-content"
      :style="'font-size: ' + fontSize + 'px'"
      contenteditable="true"
      @focus="focus"
      @click="input"
      @input="input"
      @paste.prevent="paste"
    />
    <!-- 表情 -->
    <div class="emojis">
      <div
        v-for="(item, index) in emojis"
        :key="index"
        class="emojis-item"
        @click.prevent="addEmoji(item.value)"
      >
        <img
          :src="item.value"
          loading="lazy"
        >
      </div>
    </div>
  </div>
</template>

<script>
import emoji from "@/assets/js/emoji.js";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    fontSize: {
      type: Number,
      default: 15,
    },
  },
  data() {
    return {
      show_emojis: false,
    };
  },
  computed: {
    emojis() {
      return emoji.emojis();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.editor = this.$refs.editorContent;
      this.editor.innerHTML = this.value;
      this.selection = window.getSelection();
      this.range = document.createRange();
      this.range.selectNodeContents(this.editor); // 选择编辑器的内容
      if (this.value) {
        this.range.collapse(false); // 将范围设置为末尾
        this.selection.removeAllRanges(); // 清空选择范围
        this.selection.addRange(this.range); // 将光标位置设置到末尾
      }
    });
  },
  methods: {
    cleanHtml(html) {
      // 1. 处理第一个 <div>，只移除 <div> 而不加 <br>
      let newHtml = html.replace(/^<div>/, ""); // 只替换第一个 <div>
      // 2. 替换后续所有的 <div> 标签为 <br>
      newHtml = newHtml.replace(/<div>/g, "<br>").replace(/<\/div>/g, "");
      // 3. 删除最后一个 <br> 标签，如果它是多余的
      newHtml = newHtml.replace(/(<br>\s*)+$/, ""); // 清除末尾多余的 <br>
      return newHtml;
    },
    // 内容
    contentChange() {
      const html = this.cleanHtml(this.editor.innerHTML);
      this.$emit("input", html);
    },
    focus() {
      // 获取当前的选区
      this.selection = window.getSelection();
      if (this.selection.rangeCount) {
        this.range = this.selection.getRangeAt(0);
      }
    },
    input(e) {
      if (e.type == "click" && e.target.nodeName == "IMG") {
        if (e.offsetX > 10) {
          this.range.setStartAfter(e.target);
          this.range.setEndAfter(e.target);
        } else {
          this.range.setStartBefore(e.target);
          this.range.setEndBefore(e.target);
        }
      }

      this.range = this.selection.getRangeAt(0);

      this.contentChange();
    },
    // 处理粘贴事件
    paste(event) {
      // 获取剪贴板中的文本数据
      const text = (event.clipboardData || window.clipboardData).getData(
        "text/plain"
      );
      // 使用正则表达式提取包含文本和表情的部分
      const parts = text.match(/(\[.*?\]|[^\[\s]+)/g);
      parts.forEach((part) => {
        let node = null;
        // 如果是文本，则直接插入
        if (/^[^\[\s]+$/.test(part)) {
          node = document.createTextNode(part);
        } else {
          // 否则，将表情替换为图片
          const key = part.replace(/(\[|\])/g, "");
          const emojiSrc = emoji.emoji2key(key);
          if (emojiSrc) {
            // 创建 img 元素
            node = document.createElement("img");
            node.src = emojiSrc;
            node.style.width = `${this.fontSize + 4}px`; // 设置图片大小
            node.style.height = `${this.fontSize + 4}px`; // 设置图片大小
          }
        }
        if (!node) return;
        this.range.insertNode(node);
        // 将光标移动到之后
        this.range.setStartAfter(node);
        this.range.setEndAfter(node);
      });
      this.contentChange();
    },
    addEmoji(emojiSrc) {
      if (!this.range) {
        this.editor.focus();
      }
      // this.range.deleteContents();

      // 创建 img 元素
      const imgNode = document.createElement("img");
      imgNode.src = emojiSrc;
      imgNode.style.width = `${this.fontSize + 4}px`; // 设置图片大小
      imgNode.style.height = `${this.fontSize + 4}px`; // 设置图片大小

      // 插入表情图片到光标位置
      this.range.insertNode(imgNode);

      // 创建一个新的 range，将光标移动到图片之后
      this.range.setStartAfter(imgNode);
      this.range.setEndAfter(imgNode);

      // 清除所有原有选区，确保光标保持在正确位置
      this.selection.removeAllRanges();
      this.selection.addRange(this.range);

      this.contentChange();
    },
  },
};
</script>


<style lang="scss" scope>
.editor-content {
  outline: none;
  padding: 10px;
  font-size: 15px;
  min-height: 100px;
  img {
    vertical-align: middle;
    margin-top: -2px;
  }
}
.editor {
  position: relative;
  background-color: #f0f0fc;
  border-radius: 4px;
}
.emojis {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1%;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100px;
  border-top: solid 1px #ddd;
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    border-radius: 4px;
    img {
      width: 70%;
      max-width: 40px;
    }

    &:active {
      background-color: #eee;
    }
  }
}
</style>