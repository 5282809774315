<template>
  <div class="input-number">
    <div class="btn" @click="sub">
      <svg
        t="1729991754016"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="6054"
        width="16"
        height="16"
      >
        <path
          d="M883.1 557.2H141.5c-24.8 0-44.9-20.1-44.9-44.9 0-24.8 20.1-44.9 44.9-44.9H883c24.8 0 44.9 20.1 44.9 44.9 0.1 24.7-20 44.9-44.8 44.9z"
          fill="#ffffff"
          p-id="6055"
        ></path>
      </svg>
    </div>
    <div class="input">
      <input type="number" v-model="value" @input="input" />
    </div>
    <div class="btn" @click="add">
      <svg
        t="1729991775330"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="7145"
        width="16"
        height="16"
      >
        <path
          d="M883.1 557.2H141.5c-24.8 0-44.9-20.1-44.9-44.9 0-24.8 20.1-44.9 44.9-44.9H883c24.8 0 44.9 20.1 44.9 44.9 0.1 24.7-20 44.9-44.8 44.9z"
          fill="#ffffff"
          p-id="7146"
        ></path>
        <path
          d="M512.3 927.9c-24.8 0-44.9-20.1-44.9-44.9V141.5c0-24.8 20.1-44.9 44.9-44.9 24.8 0 44.9 20.1 44.9 44.9V883c0 24.8-20.1 44.9-44.9 44.9z"
          fill="#ffffff"
          p-id="7147"
        ></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    min: {
      type: [String, Number],
      default: 0,
    },
    max: {
      type: [String, Number],
      default: 999999,
    },
    step: {
      type: [Number],
      default: 1,
    },
  },
  methods: {
    input(e) {
      let value = Number(e.target.value);
      if (value < this.min) {
        value = this.min;
      } else if (value > this.max) {
        value = this.max;
      }
      this.$emit("input", value);
    },
    add() {
      let value = Number(this.value);
      if (value < this.max) {
        value += this.step;
        value = Math.round(value * 100) / 100;
      } else {
        value = this.max;
        msg("超出最大值");
      }
      this.$emit("input", value);
    },
    sub() {
      let value = Number(this.value);
      if (value > this.min) {
        value -= this.step;
        value = Math.round(value * 100) / 100;
      } else {
        value = this.min;
        msg("超出最小值");
      }
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-number {
  display: flex;
  background-color: var(--wx-color);
  border-radius: 3px;
  > div {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}

.input {
  input {
    background-color: rgba($color: #fff, $alpha: 0.8);
    padding: 0;
    border-radius: 0;
    height: 26px;
    text-align: center;
    border-radius: 3px;
  }
}

.btn {
  cursor: pointer;
  &:active {
    background-color: #007539;
  }
}
</style>