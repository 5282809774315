import Vue from 'vue';
import Loading from '@/components/Loading.vue'

const ModalConstructor = Vue.extend(Loading);

function openModal(option) {
    const modal = new ModalConstructor({
        el: document.createElement('div'),
        data: {
            ...option
        }
    });
    modal.$mount();
    document.body.appendChild(modal.$el);
    return modal;
}

export default openModal;
