<template>
  <div>
    <div class="mask" v-if="mask"></div>
    <div v-if="visible" class="loading" :style="{ top: top }">
      <svg
        t="1729475982259"
        class="icon-loading"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1715"
        width="20"
        height="20"
      >
        <path
          d="M512 322.56a35.84 35.84 0 0 1-35.84-35.84V102.4a35.84 35.84 0 1 1 71.68 0v184.32a35.84 35.84 0 0 1-35.84 35.84zM512 957.44a35.84 35.84 0 0 1-35.84-35.84v-184.32a35.84 35.84 0 1 1 71.68 0v184.32a35.84 35.84 0 0 1-35.84 35.84zM645.95456 378.04544a35.83488 35.83488 0 0 1 0-50.68288l130.33472-130.33472a35.83488 35.83488 0 0 1 50.68288 0 35.83488 35.83488 0 0 1 0 50.68288l-130.33472 130.33472a35.83488 35.83488 0 0 1-50.68288 0zM197.02784 826.97216a35.83488 35.83488 0 0 1 0-50.68288l130.33472-130.33472a35.83488 35.83488 0 0 1 50.68288 0 35.83488 35.83488 0 0 1 0 50.68288l-130.33472 130.33472a35.83488 35.83488 0 0 1-50.68288 0zM701.44 512a35.84 35.84 0 0 1 35.84-35.84h184.32a35.84 35.84 0 1 1 0 71.68h-184.32a35.84 35.84 0 0 1-35.84-35.84zM66.56 512a35.84 35.84 0 0 1 35.84-35.84h184.32a35.84 35.84 0 1 1 0 71.68H102.4a35.84 35.84 0 0 1-35.84-35.84zM645.95456 645.95456a35.83488 35.83488 0 0 1 50.68288 0l130.33472 130.33472a35.83488 35.83488 0 0 1 0 50.68288 35.83488 35.83488 0 0 1-50.68288 0l-130.33472-130.33472a35.83488 35.83488 0 0 1 0-50.68288zM197.02784 197.02784a35.83488 35.83488 0 0 1 50.68288 0l130.33472 130.33472a35.83488 35.83488 0 0 1 0 50.68288 35.83488 35.83488 0 0 1-50.68288 0L197.02784 247.71072a35.83488 35.83488 0 0 1 0-50.68288z"
          fill="#ffffff"
          p-id="1716"
        />
      </svg>
      <div v-if="title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      visible: false,
      top: "50%",
      title: "",
      mask:false,
    };
  },
  methods: {
    show(title) {
      if (title) {
        this.title = title;
      }
      this.visible = true;
    },
    close(remove=true) {
      this.visible = false;
      if(remove){
        this.$el.remove();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.2);
  z-index: 999;
}
.loading {
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  background-color: rgba($color: #000000, $alpha: 0.8);
  height: 40px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 13px;
  padding: 0 15px;
  border-radius: 20px;
  z-index: 1000;

  .icon-loading {
    color: #fff;
    margin-right: 5px;
    animation: rotate 1s linear infinite;
  }
}
</style>