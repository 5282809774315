import Vue from 'vue';
import Cropper from '@/components/Cropper.vue'

const ModalConstructor = Vue.extend(Cropper);

let modalInstance = null;

function openModal(option) {
    modalInstance = new ModalConstructor({
        el: document.createElement('div'),
        data: {
            fixedNumber:[1,1],
            title:'头像裁剪',
            ...option
        }
    });
    modalInstance.$mount();
    document.body.appendChild(modalInstance.$el);
}

export default openModal;
