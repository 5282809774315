<template>
  <div v-if="visible">
    <div class="mask" />
    <div class="body">
      <div class="title">
        {{ title }}
      </div>
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          class="vue-cropper"
          :img="img"
          :info="true"
          :can-scale="true"
          :auto-crop="true"
          :fixed="true"
          :fixed-box="true"
          :can-move-box="false"
          :center-box="true"
          :fixed-number="fixedNumber"
          mode="cover"
          @imgLoad="imgLoad"
        />
      </div>
      <div class="option">
        <div
          class="option-item"
          @click="$refs.cropper.changeScale(2)"
        >
          <svg
            t="1724123361574"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="4264"
            width="25"
            height="25"
          >
            <path
              d="M640 456h-118.4V320a32 32 0 0 0-64 0v136H320a32 32 0 0 0 0 64h137.6V640a32 32 0 1 0 64 0v-120H640a32 32 0 1 0 0-64z"
              p-id="4265"
            />
            <path
              d="M919.264 905.984l-138.912-138.912C851.808 692.32 896 591.328 896 480c0-229.376-186.624-416-416-416S64 250.624 64 480s186.624 416 416 416c95.008 0 182.432-32.384 252.544-86.208l141.44 141.44a31.904 31.904 0 0 0 45.248 0 32 32 0 0 0 0.032-45.248zM128 480C128 285.92 285.92 128 480 128s352 157.92 352 352-157.92 352-352 352S128 674.08 128 480z"
              p-id="4266"
            />
          </svg>
        </div>
        <div
          class="option-item"
          @click="$refs.cropper.changeScale(-2)"
        >
          <svg
            t="1724123384136"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="5264"
            width="25"
            height="25"
          >
            <path
              d="M919.264 905.984l-138.912-138.912C851.808 692.32 896 591.328 896 480c0-229.376-186.624-416-416-416S64 250.624 64 480s186.624 416 416 416c95.008 0 182.432-32.384 252.544-86.208l141.44 141.44a31.904 31.904 0 0 0 45.248 0 32 32 0 0 0 0.032-45.248zM128 480C128 285.92 285.92 128 480 128s352 157.92 352 352-157.92 352-352 352S128 674.08 128 480z"
              p-id="5265"
            />
            <path
              d="M625.792 448H336a32 32 0 0 0 0 64h289.792a32 32 0 1 0 0-64z"
              p-id="5266"
            />
          </svg>
        </div>
        <div
          class="option-item"
          @click="$refs.cropper.rotateLeft()"
        >
          <svg
            t="1724123485049"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6397"
            width="25"
            height="25"
          >
            <path
              d="M288 352v192h128v64h-128v64h192v-320h-192z m128 128h-64v-64h64v64zM544 352v320h192v-320h-192z m128 256h-64v-192h64v192zM957.44 531.84h1.344v-1.92l-1.344 1.92z"
              p-id="6398"
            />
            <path
              d="M46.72 474.88l35.84 55.04 1.28 1.92 35.84 55.04 60.16-119.04-32 1.92C168.96 277.76 331.52 128 529.28 128c211.904 0 384 172.16 384 384s-172.096 384-384 384c-140.16 0-263.04-75.52-329.6-188.16h-1.92l-0.64 0.64-53.12 31.36A446.592 446.592 0 0 0 529.28 960c247.744 0 448-200.32 448-448s-200.256-448-448-448C294.4 64 102.4 243.84 83.2 472.96l-36.48 1.92z"
              p-id="6399"
            />
          </svg>
        </div>
        <div
          class="option-item"
          @click="$refs.cropper.rotateRight()"
        >
          <svg
            t="1724123505759"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="6560"
            width="25"
            height="25"
          >
            <path
              d="M288 352v192h128v64h-128v64h192v-320h-192z m128 128h-64v-64h64v64zM544 352v320h192v-320h-192z m128 256h-64v-192h64v192zM957.44 531.84h1.344v-1.92l-1.344 1.92z"
              p-id="6561"
            />
            <path
              d="M994.56 474.88l-35.84 55.04-1.28 1.92-35.84 55.04-60.16-119.04 32 1.92C872.32 277.76 709.76 128 512 128 300.16 128 128 300.16 128 512s172.16 384 384 384c140.16 0 263.04-75.52 329.6-188.16h1.856l0.704 0.704 53.056 31.296A446.4 446.4 0 0 1 512 960c-247.68 0-448-200.32-448-448s200.32-448 448-448c234.88 0 426.88 179.84 446.08 408.96l36.48 1.92z"
              p-id="6562"
            />
          </svg>
        </div>
      </div>
      <div class="buttons">
        <div
          class="buttons-item"
          @click="cancel"
        >
          取消
        </div>
        <div
          class="buttons-item"
          @click="startCrop"
        >
          完成
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      visible:true,
      img:''
    }
  },
  methods: {
    startCrop() {
      this.$refs.cropper.getCropData((data) => {
        if(typeof this.confirm === 'function'){
          this.confirm(data);
        }
        msg("截图成功");
        this.cancel();
      });
    },
    cancel() {
      this.visible = false;
    },
    imgLoad() {},
  },
};
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
}
.body {
  position: fixed;
  left: 50%;
  top: 50%;
  max-width: 90%;
  width: 500px;
  background-color: #fff;
  z-index: 1001;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;
  user-select: none;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  .title {
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    background-color: #fff;
    color: #444;
  }
  .cropper {
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    .vue-cropper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .option {
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 30px;
    &-item {
      height: 40px;
      width: 40px;
      line-height: 40px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 6px;
      &:active {
        background-color: #e1e1e1;
      }
    }
  }
  .buttons {
    display: flex;
    border-top: solid 1px #f1f1f1;
    &-item {
      flex: 1;
      height: 50px;
      line-height: 50px;
      user-select: none;
      cursor: pointer;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-child {
        border-left: solid 1px #f1f1f1;
        color: var(--wx-color);
      }
      &:active {
        background-color: #e1e1e1;
      }
    }
  }
}
</style>