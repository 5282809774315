import Vue from 'vue';
import Popup from '@/components/Popup.vue'

const ModalConstructor = Vue.extend(Popup);

let modalInstances = {};

function openModal(id, option) {

    let modal = id ? modalInstances[id] : null;
    
    if (modal) {
        modal.visible = true;
    } else {
        modal = new ModalConstructor({
            el: document.createElement('div'),
            data: {
                ...option
            }
        });
        modal.$mount();
        document.body.appendChild(modal.$el);
    }
}

export default openModal;
