<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
window.msg = function (text, duration = 2000) {
  if (window.msgTimer) {
    clearTimeout(msgTimer);
    window.msgTimer = null;
    document.querySelector(".msg").remove();
  }
  window.msgTimer = setTimeout(() => {
    document.body.removeChild(el);
    window.msgTimer = null;
  }, duration);
  const el = document.createElement("div");
  el.innerText = text;
  el.className = "msg";
  document.body.appendChild(el);
};

import Home from "./components/Home.vue";

export default {
  name: "App",
  components: {
    Home,
  },
  mounted() {
    const handleFontSize = () => {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke('setFontSizeCallback', {fontSize: 0});
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on('menu:setfont', function() {
        WeixinJSBridge.invoke('setFontSizeCallback', {fontSize: 0});
      });
    };
    try {
      // 安卓 禁止用户自定义设置字体大小
      if (typeof WeixinJSBridge == 'object' && typeof WeixinJSBridge.invoke == 'function') {
        handleFontSize();
      } else {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', handleFontSize, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', handleFontSize);
          document.attachEvent('onWeixinJSBridgeReady', handleFontSize);
        }
      }
    } catch (error) {}
  },
};
</script>

<style lang="scss">
:root {
  --wx-color: #06c05f;
}
body {
  padding: 0;
  margin: 0;

  /* IOS 禁止用户自定义设置字体大小 */
  -webkit-text-size-adjust: 105% !important;
  text-size-adjust: 105% !important;
  -moz-text-size-adjust: 105% !important;
}

#app {
  position: relative;
}

input,
select,
textarea {
  padding: 0;
  margin: 0;
  border-width: 0;
  background-color: #f0f0fc;
  outline: none;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  line-height: 1;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
}

textarea {
  padding: 10px;
  min-height: 100px;
  resize: none;
}

.radio-group {
  display: flex;
  gap: 10px;
  &-item {
    font-size: 13px;
    flex: 1;
    background-color: #eeeeee;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: solid 1px var(--wx-color);
      border-radius: 10px;
      vertical-align: middle;
      margin-right: 5px;
    }
    &.active {
      background-color: var(--wx-color);
      color: #fff;
      &::before {
        background-color: #fff;
      }
    }
  }
}

// 消息
.msg {
  position: fixed;
  z-index: 10001;
  left: 50%;
  top: 50%;
  max-width: 60%;
  transform: translate(-50%, -50%);
  background-color: rgba($color: #000000, $alpha: 0.8);
  color: #fff;
  padding: 10px 13px;
  font-size: 13px;
  border-radius: 6px;
}
</style>
