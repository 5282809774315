<template>
  <div style="position: relative">
    <div
      style="
        background-color: rgba(255, 255, 255, 0.5);
        min-height: 80vh;
        border-radius: 5px;
        margin-bottom: 15px;
      "
    >
      <img
        :src="base64"
        style="
          width: 100%;
          height: auto;
          border-radius: 5px;
          vertical-align: middle;
        "
      />
    </div>
  </div>
</template>

<script>
import Draw from "@/assets/js/draw.js";
import { formatDate } from "@/assets/js/common";
export default {
  props: {
    baseinfo: {
      type: Object,
      default() {
        return {};
      },
    },
    param: {
      type: Object,
      default() {
        return {};
      },
    },
    likes: {
      type: Array,
      default() {
        return [];
      },
    },
    comments: {
      type: Array,
      default() {
        return [];
      },
    },
    isView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      base64: "",
      isRefresh: false,
      clears: [],
    };
  },
  watch: {
    baseinfo: {
      handler(newValue, oldValue) {
        if (
          newValue.nickname != this.oldValue_nickname ||
          newValue.avatar != this.oldValue_avatar
        ) {
          this.clearCache("status");
        }
        this.clearCache("dynamic");
        this.oldValue_nickname = newValue.nickname;
        this.oldValue_avatar = newValue.avatar;
      },
      deep: true,
    },
    likes: {
      handler(newValue, oldValue) {
        this.clearCache("likes");
      },
      deep: true,
    },
    comments: {
      handler(newValue, oldValue) {
        this.clearCache("comment");
      },
      deep: true,
    },
    param: {
      handler(newValue, oldValue) {
        let changeKey = null;
        if (!this.oldValue) {
          changeKey = "build";
        } else {
          // 拿到改变的值
          for (let key in newValue) {
            if (newValue[key] !== this.oldValue[key]) {
              changeKey = key;
            }
          }
        }
        this.oldValue = JSON.parse(JSON.stringify(newValue));

        // 只更新status的项
        if (
          [
            "time",
            "power",
            "system",
            "wifi",
            "network",
            "charge",
            "cover",
          ].indexOf(changeKey) > -1
        ) {
          this.clearCache("status");
        }
        // 要更新所有的
        else if (changeKey == "dark" || changeKey == "build") {
          // 改变深色模式要全部重新生成
          this.clearCache([
            "status",
            "dynamic",
            "likes",
            "comment",
            "dynamics",
            "inputbar",
          ]);
        } else if (changeKey == "screenshot") {
          this.clearCache("screenshot");
        } else if (changeKey == "showcover") {
          this.clearCache(["status", "dynamics"]);
        } else if (changeKey == "likenum") {
          this.clearCache(["likes", "comment"]);
        } else if (changeKey == "picture_width" || changeKey == "top" || changeKey == "limit") {
          this.clearCache(["dynamic"]);
        } else if (changeKey == "nickname_size" || changeKey == "text_size") {
          if (newValue.build == 1) {
            this.clearCache(["likes", "dynamics"]);
          }
          this.clearCache(["dynamic", "comment"]);
        }
      },
      deep: true,
    },
  },
  mounted() {
    const dpr = window.devicePixelRatio;

    this.draw = new Draw({
      dpr,
      canvasWidth: 470,
      canvasHeight: 1020,
    });

    this.oldValue = null;

    this.$nextTick(() => {
      this.loading = this.$loading({
        top: "20%",
        title: "拼命绘画中...",
      });
    });
  },
  methods: {
    download() {
      // 创建一个临时的 a 元素用于触发下载
      const link = document.createElement("a");
      link.href = this.base64;
      link.download =
        "朋友圈截图" + formatDate(new Date(), "MMddHHmm") + ".png"; // 设置下载文件名
      link.click(); // 触发点击事件进行下载
    },
    clearCache(clearKey) {
      if (!this.draw) return;
      clearKey = clearKey || [];
      if (!Array.isArray(clearKey)) {
        clearKey = [clearKey];
      }
      clearKey.forEach((i) => {
        // 将更新的项加入到数组中
        if (this.clears.indexOf(i) == -1) {
          this.clears.push(i);
        }
      });
      if (this.isView) {
        this.build();
      }
    },
    build() {
      if (!this.clears.length || this.isRefresh) return;
      // 清除缓存
      this.clears.forEach((key) => {
        this.draw.clearCache(key);
      });
      this.clears = [];
      this.refresh();
    },
    // 刷新
    refresh() {
      // 如果还没有刷新完
      if (this.isRefresh) {
        return;
      }
      this.isRefresh = true;
      this.loading.show();
      this.draw
        .refresh({
          baseinfo: this.baseinfo,
          likes: this.likes,
          comments: this.comments,
          param: this.param,
        })
        .then((res) => {
          // 等待300ms
          setTimeout(() => {
            this.base64 = res;
            this.loading.close(false);
            this.isRefresh = false;
            msg("绘画完成", 1500);
            this.build();
          }, 300);
        })
        .catch(() => {
          msg("绘画失败", 1500);
          this.loading.close(false);
          this.isRefresh = false;
        });
    },
  },
};
</script>
