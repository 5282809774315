const list = [{
    key: '微笑',
    value: 'weixiao'
},
{
    key: '撇嘴',
    value: 'piezui'
},
{
    key: '色',
    value: 'se'
},
{
    key: '发呆',
    value: 'fadai'
},
{
    key: '得意',
    value: 'deyi'
},
{
    key: '流泪',
    value: 'liulei'
},
{
    key: '害羞',
    value: 'haixiu'
},
{
    key: '闭嘴',
    value: 'bizui'
},
{
    key: '睡',
    value: 'shui'
},
{
    key: '大哭',
    value: 'daku'
},
{
    key: '尴尬',
    value: 'ganga'
},
{
    key: '发怒',
    value: 'fanu'
},
{
    key: '调皮',
    value: 'tiaopi'
},
{
    key: '呲牙',
    value: 'ziya'
},
{
    key: '惊讶',
    value: 'jingya'
},
{
    key: '难过',
    value: 'nanguo'
},
{
    key: '囧',
    value: 'jiong'
},
{
    key: '抓狂',
    value: 'zhuakuang'
},
{
    key: '吐',
    value: 'tu'
},
{
    key: '偷笑',
    value: 'touxiao'
},
{
    key: '愉快',
    value: 'yukuai'
},
{
    key: '白眼',
    value: 'baiyan'
},
{
    key: '傲慢',
    value: 'aoman'
},
{
    key: '困',
    value: 'kun'
},
{
    key: '惊恐',
    value: 'jingkong'
},
{
    key: '憨笑',
    value: 'hanxiao'
},
{
    key: '悠闲',
    value: 'youxian'
},
{
    key: '咒骂',
    value: 'zhouma'
},
{
    key: '疑问',
    value: 'yiwen'
},
{
    key: '嘘',
    value: 'xu'
},
{
    key: '晕',
    value: 'yun'
},
{
    key: '衰',
    value: 'shuai'
},
{
    key: '骷髅',
    value: 'kulou'
},
{
    key: '敲打',
    value: 'qiaoda'
},
{
    key: '再见',
    value: 'zaijian'
},
{
    key: '擦汗',
    value: 'cahan'
},
{
    key: '抠鼻',
    value: 'koubi'
},
{
    key: '鼓掌',
    value: 'guzhang'
},
{
    key: '坏笑',
    value: 'huaixiao'
},
{
    key: '右哼哼',
    value: 'youhengheng'
},
{
    key: '鄙视',
    value: 'bishi'
},
{
    key: '委屈',
    value: 'weiqu'
},
{
    key: '快哭了',
    value: 'kuaikule'
},
{
    key: '阴险',
    value: 'yinxian'
},
{
    key: '亲亲',
    value: 'qinqin'
},
{
    key: '可怜',
    value: 'kelian'
},
{
    key: '笑脸',
    value: 'xiaolian'
},
{
    key: '生病',
    value: 'shengbing'
},
{
    key: '脸红',
    value: 'lianhong'
},
{
    key: '破涕为笑',
    value: 'potiweixiao'
},
{
    key: '恐惧',
    value: 'kongju'
},
{
    key: '失望',
    value: 'shiwang'
},
{
    key: '无语',
    value: 'wuyu'
},
{
    key: '嘿哈',
    value: 'heiha'
},
{
    key: '捂脸',
    value: 'wulian'
},
{
    key: '奸笑',
    value: 'jianxiao'
},
{
    key: '机智',
    value: 'jizhi'
},
{
    key: '皱眉',
    value: 'zhoumei'
},
{
    key: '耶',
    value: 'ye'
},
{
    key: '吃瓜',
    value: 'chigua'
},
{
    key: '加油',
    value: 'jiayou'
},
{
    key: '汗',
    value: 'han'
},
{
    key: '天啊',
    value: 'tiana'
},
{
    key: 'Emm',
    value: 'chenmo'
},
{
    key: '社会社会',
    value: 'shehui'
},
{
    key: '旺柴',
    value: 'wangchai'
},
{
    key: '好的',
    value: 'haode'
},
{
    key: '打脸',
    value: 'dalian'
},
{
    key: '哇',
    value: 'wa'
},
{
    key: '翻白眼',
    value: 'fanbaiyan'
},
{
    key: '666',
    value: '666'
},
{
    key: 'OK',
    value: 'ok'
},
{
    key: '让我看看',
    value: 'rangwokankan'
},
{
    key: '叹气',
    value: 'tanqi'
},
{
    key: '苦涩',
    value: 'kuse'
},
{
    key: '裂开',
    value: 'liekai'
},
{
    key: '嘴唇',
    value: 'zuichun'
},
{
    key: '爱心',
    value: 'aixin'
},
{
    key: '心碎',
    value: 'xinsui'
},
{
    key: '拥抱',
    value: 'yongbao'
},
{
    key: '强',
    value: 'qiang'
},
{
    key: '弱',
    value: 'ruo'
},
{
    key: '握手',
    value: 'woshou'
},
{
    key: '胜利',
    value: 'shengli'
},
{
    key: '抱拳',
    value: 'baoquan'
},
{
    key: '勾引',
    value: 'gouyin'
},
{
    key: '拳头',
    value: 'quantou'
},
{
    key: '合十',
    value: 'heshi'
},
{
    key: '啤酒',
    value: 'pijiu'
},
{
    key: '咖啡',
    value: 'kafei'
},
{
    key: '蛋糕',
    value: 'dangao'
},
{
    key: '玫瑰',
    value: 'meigui'
},
{
    key: '凋谢',
    value: 'diaoxie'
},
{
    key: '菜刀',
    value: 'caidao'
},
{
    key: '炸弹',
    value: 'zhadan'
},
{
    key: '便便',
    value: 'bianbian'
},
{
    key: '月亮',
    value: 'yueliang'
},
{
    key: '太阳',
    value: 'taiyang'
},
{
    key: '庆祝',
    value: 'qingzhu'
},
{
    key: '礼物',
    value: 'liwu'
},
{
    key: '红包',
    value: 'hongbao'
},
{
    key: '發',
    value: 'fa'
},
{
    key: '福',
    value: 'fu'
},
{
    key: '烟花',
    value: 'yanhua'
},
{
    key: '爆竹',
    value: 'baozhu'
},
{
    key: '猪头',
    value: 'zhutou'
},
{
    key: '跳跳',
    value: 'tiaotiao'
},
{
    key: '发抖',
    value: 'fadou'
},
{
    key: '转圈',
    value: 'zhuanquan'
}
];

let isInit = false

const kv = {};
list.forEach(i => {
    kv[i.key] = '/emjoi/' + i.value + '.png';
    // 预加载
    new Image().src = kv[i.key];
})

// 拿到表情列表
const emojis = () => {
    if (isInit) {
        return list;
    }
    let rets = list.map(i => {
        i.value = '/emjoi/' + i.value + '.png'
        return i;
    });
    isInit = true;
    return rets;
}

// 按名称拿到路径
const emoji2key = (key) => {
    if (kv.hasOwnProperty(key)) {
        return kv[key];
    }
    return null;
}

export {
    emoji2key,
    emojis
}

export default {
    emoji2key,
    emojis
}