<template>
  <div class="date">
    <div class="date-item">
      <template v-if="type === 'datetime' || type === 'date'">
        <div
          class="date-input"
          style="flex: 1.5;"
        >
          <select @change="dateChange($event, 'year')">
            <option
              v-for="(item, index) in date.years"
              :key="index"
              :selected="item == current_date.year"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div class="date-hr">
          年
        </div>
        <div class="date-input">
          <select @change="dateChange($event, 'month')">
            <option
              v-for="(item, index) in date.months"
              :key="index"
              :selected="item == current_date.month"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div class="date-hr">
          月
        </div>
        <div class="date-input">
          <select @change="dateChange($event, 'day')">
            <option
              v-for="(item, index) in date.days"
              :key="index"
              :selected="item == current_date.day"
              :value="item"
            >
              {{ item }}
            </option>
          </select>
        </div>
        <div class="date-hr">
          日
        </div>
      </template>
      <!-- </div> -->
      <!-- <div class="date-item" v-if="type === 'datetime' || type === 'time'"> -->
      <template v-if="type === 'datetime' || type === 'time'">
        <div class="date-input">
          <select @change="dateChange($event, 'hour')">
            <option
              v-for="(item, index) in date.hours"
              :key="index"
              :selected="item == current_date.hour"
              :value="item"
            >
              {{ item | padStart }}
            </option>
          </select>
        </div>
        <div class="date-hr">
          :
        </div>
        <div class="date-input">
          <select @change="dateChange($event, 'minute')">
            <option
              v-for="(item, index) in date.minutes"
              :key="index"
              :selected="item == current_date.minute"
              :value="item"
            >
              {{ item | padStart }}
            </option>
          </select>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const date = {
  // 创建年月日
  years: [...Array(100).keys()].map((item) => {
    return item + 1990;
  }),
  months: [...Array(12).keys()].map((item) => {
    return item + 1;
  }),
  days: [...Array(31).keys()].map((item) => {
    return item + 1;
  }),
  hours: [...Array(24).keys()].map((item) => {
    return item;
  }),
  minutes: [...Array(60).keys()].map((item) => {
    return item;
  }),
};

export default {
  props: {
    type: {
      type: String,
      default: "datetime",
    },
    value: {
      type: [String, Date],
      default: () => new Date(),
    },
  },
  data() {
    return {
      date: {
        years: [],
        months: [],
        days: [],
        hours: [],
        minutes: [],
      },
      current_date: {
        year: 2022,
        month: 1,
        day: 1,
        hour: 0,
        minute: 0,
      },
    };
  },
  created() {
    this.date = date;
    const dateVal = new Date(this.value);
    const today =
      this.value instanceof Date
        ? this.value
        : isNaN(dateVal)
          ? new Date()
          : dateVal;
    this.current_date = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate(),
      hour: today.getHours(),
      minute: today.getMinutes(),
    };
    this.$nextTick(() => {
      this.update();
    });
  },
  methods: {
    dateChange(e, key) {
      this.current_date[key] = e.target.value;
      this.update();
    },
    update() {
      const dateStr =
        this.current_date.year +
        "/" +
        this.current_date.month +
        "/" +
        this.current_date.day +
        " " +
        this.current_date.hour +
        ":" +
        this.current_date.minute +
        ":00";
      this.$emit("input", new Date(dateStr));
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  font-size: 13px;

  &-item {
    display: flex;
    align-items: center;
    gap: 4px;

    // &:nth-child(2) {
    //   margin-top: 10px;
    // }
  }

  &-input {
    // width: 70px;
    flex: 1;
    text-align: center;

    select {
      // width: 5em;
      height: 34px;
      text-align: center;
      font-size: 14px;
      padding: 0;
    }
  }

  &-hr {
    // width: 26px;
    font-size: 12px;
    text-align: center;
  }
}
</style>