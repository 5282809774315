// 时间函数
// 日期到时间戳
function strTotime(str) {
    if (!str) return null;
    if(str instanceof Date) return str;
    // -转换成/
    str = str.replaceAll('-', '/');
    // 年月日替换成/
    str = str.replaceAll(/[年月]+/g, '/');
    // 日替换成空
    str = str.replaceAll('日', '');
    // 点替换成:
    str = str.replaceAll('点', ':');
    // 转成时间戳
    return new Date(str).getTime();
}
// 格式化时间
function formatDate(date, format = 'yyyy-MM-dd HH:mm') {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }
    if (isNaN(date)) {
        date = new Date();
    }
    const replacements = {
        'yyyy': date.getFullYear(),
        'MM': String(date.getMonth() + 1).padStart(2, '0'),
        'dd': String(date.getDate()).padStart(2, '0'),
        'HH': String(date.getHours()).padStart(2, '0'),
        'mm': String(date.getMinutes()).padStart(2, '0'),
        'ss': String(date.getSeconds()).padStart(2, '0')
    };
    return format.replace(/yyyy|MM|dd|HH|mm|ss/g, matched => replacements[matched]);
}
/**
 * 个性化显示时间
 * @param {Date} date 时间 
 * @param {String} level 等级 minutes hours days
 * @returns 
 */
function timeAgo(date) {
    date = strTotime(date);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const returnDate = formatDate(date, 'yyyy月MM日dd HH:mm');

    const formatDateOrRelativeTime = (time, threshold, label) =>
        time > threshold ? returnDate : `${time}${label}`;

    if (seconds > 86400) {
        if (days == 1) {
            return '昨天';
        } else if (days == 2) {
            return '前天';
        }
        return formatDateOrRelativeTime(days, 3, '天前');
    } else if (seconds > 3600) {
        return formatDateOrRelativeTime(hours, 24, '小时前');
    } else if (seconds > 60) {
        return formatDateOrRelativeTime(minutes, 60, '分钟前');
    } else if (seconds < 0) {
        return returnDate;
    }
    return '刚刚';
}

/**
 * 个性化显示日期
 * @param {Date} date 时间 
 * @returns 
 */
function dateAgo(date) {
    date = strTotime(date);
    const now = new Date();

    if(date.getFullYear() == now.getFullYear() && date.getMonth() == now.getMonth()){
        const diff = now.getDate() - date.getDate();
        const time = formatDate(date, 'HH:mm');
        if(diff == 0){
            return time;
        }
        if(diff == 1){
            return '昨天 '+time;
        }
        if(diff == 2){
            return '前天 '+time;
        }
    }
    return formatDate(date, 'yyyy年MM月dd日 HH:mm');
}

/**
 * 取一个区间时间
 * @param {Date} start 开始时间
 * @param {Date} end 结束时间
 * @returns Date
 */
function getRandomTimeRange(start, end) {
    // 将时间转换为毫秒
    const startTime = new Date(start).getTime();
    const endTime = new Date(end).getTime();
    // 生成开始时间和结束时间的随机数
    const randomStartTime = Math.floor(Math.random() * (endTime - startTime)) + startTime;
    // 转换成时间
    return new Date(randomStartTime);
}

 // 生成一个指定的随机数
function random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}




module.exports = {
    random,
    strTotime,
    formatDate,
    timeAgo,
    dateAgo,
    getRandomTimeRange
}